@import '../node_modules/normalize.css/normalize.css';

body {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
	font-size: 16px;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: #82a3bf;
}
a:hover {
	text-decoration: underline;
}

.App {
}

.App-header {
	padding: 20px 48px;
	background: #1d1f20;
	color: #c5c9c6;
	text-align: center;
	margin: 0 0 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row wrap;
}

.App-logo {
	display: block;
	width: 64px;
	height: auto;
	margin: 0;
}

.App-desc {
	margin: 0 32px;
	min-width: 280px;
}

.App-title {
	font-size: 24px;
	font-weight: normal;
	background: -webkit-linear-gradient(45deg, #b4bc67, #f0c775, #cc6666, #b295bb, #82a3bf, #e9b36d, #de935e);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@media screen and (min-width: 1028px) {
	.App-logo {
		width: 128px;
	}
	.App-desc {
		min-width: 400px;
	}
	.App-title {
		font-size: 48px;
	}
}

.App-demo {
	margin: 0 64px 32px;
}

.App-guide {
	margin: 0 64px;
	text-align: center;
}

.disclaimer {
	color: #cc6666;
	text-transform: lowercase;
	font-style: italic;
}

.links {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}
.links a {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 48px;
	padding: 0 64px;
	margin: 16px;
	border-bottom: 4px solid;
	opacity: 0.9;
	will-change: opacity;
	transition: opacity 300ms;
	text-decoration: none;
	font-size: 18px;
	text-transform: uppercase;
}
.links a:hover {
	opacity: 1;
}

.links a.vscode {
	background-color: #373277;
	border-bottom-color: #282558;
	color: #fff;
}
.links a.github {
	background-color: #6e5494;
	border-bottom-color: #533f70;
	color: #fff;
}
.links a.eform {
	background-color: #CB3340;
	border-bottom-color: rgb(153, 37, 47);
	color: #fff;
}

.App-footer {
	padding: 16px 48px;
	background: #1d1f20;
	color: #c5c9c6;
	text-align: center;
}
